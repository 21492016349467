<template>
  <div v-if="$createDate(entry.begin).getTime() >= new Date().getTime()">
    <transition name="flixFadeIn">
      <div :key="status">
        <div v-if="!status">
          <div class="flix-form-group">
            <transition name="flixFadeIn">
              <div :key="open">
                <startText v-if="open" :entry="entry" :callback="function(ret) { setNote(ret) }" />
                <a href="#" v-for="(status) in statusBtn" :key="status" @click.prevent="checkStatus(status)" class="flix-btn flix-btn-xs flix-btn-block" :class="getClass(status)">
                  <flixIcon :id="$getStatusIcon({status: status})"/> {{ $t('message.'+status) }}
                </a>
              </div>
            </transition>
          </div>
          <div class="flix-form-group" :class="{'flix-blink': entry.status === 'open' && !open}"><a href="#" @click.prevent="open = !open" :key="status" class="flix-html-a" :class="{'flix-text-danger': entry.status === 'open' && !open}">
            &nbsp;<flixIcon id="warning-sign" class="flix-text-danger" style="font-size:1.5em" v-if="entry.status === 'open'"/>
            &nbsp;<span v-if="!open">{{ $t('message.edit', {name: $t('message.status')}) }}</span>
            <span v-else>{{ $t('message.close', {name: $t('message.edit', {name: $t('message.status')})}) }}</span>
            &nbsp;
            <flixIcon :id="'chevron-down'" v-if="open" />
            <flixIcon :id="'chevron-up'" v-else /></a>
          </div>
        </div>
        <div>
          <statusConfirm v-if="status" :note="note" :entry="entry" :status="status" :callback="function (ret) { confirmStatus(ret) }" />
        </div>
    </div>
  </transition>
  </div>
</template>

<script>

export default {
  props: { entry: Object, callback: Function },
  components: {
    startText () { return import('@/components/booking/status_text.vue') },
    statusConfirm () { return import('@/components/booking/status_confirm.vue') }
  },
  data () {
    return {
      open: false,
      status: false,
      variables: false,
      note: '',
      confirm: false
    }
  },
  methods: {
    setNote (ret) {
      this.note = ret
    },
    getClass (status) {
      if (status !== this.entry.status) {
        return 'flix-btn-default'
      }
      return 'flix-btn-' + this.$getStatusClass(status)
    },
    confirmStatus (check) {
      if (!check) {
        this.status = false
        return false
      }
      this.changeStatus()
    },
    checkStatus (status) {
      this.status = false
      if (status === this.entry.status) {
        this.open = !this.open
        return false
      }
      this.status = status
    },
    changeStatus () {
      this.entry.status = this.status
      this.callback(this.entry)
      this.open = false
      this.$store.dispatch('changeEntryStatus', Object.assign(this.entry, { note: this.note }))
      this.status = false
    }
  },
  mounted () {
  },
  computed: {
    statusBtn () {
      var statusBtn = []

      if (!this.open) {
        statusBtn.push(this.entry.status)
        return statusBtn
      }

      var status = ['commitment', 'cancellation', 'open']

      if (this.entry.status !== 'open') {
        status.splice(2, 1)
      }
      status.forEach(function (v) {
        statusBtn.push(v)
      })

      return statusBtn
    }
  }
}

</script>
<style scoped lang="sass">

@keyframes blink
  from
    opacity: 0.3
  50%
    opacity: 1
  to
    opacity: 0.3

.flix-btn-xs.flix-btn-warning, .flix-blink
  animation: .8s blink ease-in infinite

.flixFadeIn-enter .flixFadeIn-leave
  opacity: 0
  transform: scaleY(0)
  transform-origin: center

.flixFadeIn-enter-active
  animation: flixFadeIn 0.3s ease-in-out
  transform-origin: center

.flixFadeIn-leave-active
  opacity: 0

@keyframes flixFadeIn
  from
    opacity: 0
    transform: scaleY(0)
  to
    opacity: 1
    transform: scaleY(1)

</style>
